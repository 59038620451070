<template lang="pug">
  .FormDocuments
    div(v-if="!loading")
      article.mt-7.mb-3
        p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
          | Ingresa a continuación el número de formula,
        p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
          | autorización o pre-autorización.
      banner-component.text-center.block.mx-auto ¿Dónde encuentro este número?
    p(v-if="loading").text-sm.text-info_color.mx-auto.max-w-xs.text-center.mt-5
      | Estamos verficando la información ingresada
    form#formUserValidationDocs.input-container.m-3.mx-12(
      v-if="formDocuments.length"
      @submit.prevent="handleContinue"
    )
      .flex.items-center.justify-stretch(
        v-for="(formDocument, formDocumentIndex) in formDocuments"
        :key="formDocumentIndex"
        :id="`formDocument_label_${formDocumentIndex}`")
        div(@click="() => handleGoToEdition(formDocument)").w-full.cursor-pointer
          input-component(
            :data-valuedk="formDocument.numero"
            :onChange="handleChangeForm"
            :value="formDocument.numero"
            :id="formDocument.id"
            name="formDocument"
            type="text"
            mode="input"
            appendMore
            required
            :class="formDocumentIndex + 1 === formDocuments.length && 'greenDk'"
          ).w-full
        div(@click="() => handleGoToEdition(formDocument)")
          button(
            :class="((formDocument.files && !formDocument.files.length) || loading) ? 'opacity-50 cursor-not-allowed' : ''"
            type="button"
          ).block.m-4.my-0.mt-4.Arrow.ArrowScoped.rounded-bd-complete.flex.items-center.justify-center.border-none.outline-none
            span.material-icons-outlined.text-primary_other image
        button(
          @click="() => questionDelete(formDocument, formDocumentIndex)"
          v-if="!loading"
          type="button"
        ).border-none.block.mt-5
          .material-icons.text-info_color close
        img.w-full.maximagLoad.mt-2(v-if="loading" src="https://ntrens.com.ar/imagenes/cargando.gif" alt="loading")
    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full
      button-component(
        text="Agregar fórmula o autorización"
        themeSelected="light"
        aditional="font-bold mb-3 text-secondary"
        icon="add"
        :onClick="handleAddForm"
      )
      button-component(
        text="Continuar"
        themeSelected="secondary"
        form="formUserValidationDocs"
        type="submit"
        :disabled="!formDocuments.length || loading"
      )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import loadingMessage from '../utils/loadingMessage';

export default {
  name: "FormDocuments",
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    InputComponent: () => import("../components/general/Input.vue"),
    BannerComponent: () => import("../components/general/Banner.vue"),
  },
  computed: {
    ...mapState({
      formDocuments: (state) => state.formDocuments.formDocumentsState,
      formDocumentsFinalState: (state) => state.formDocuments.formDocumentsFinalState,
      error: (state) => state.formDocuments.error,
      loading: (state) => state.formDocuments.loading,
      env: (state) => state.environment.environment.env,
      userData: (state) => state.root.userData,
      eps: (state) => state.eps.epsState,
      external_token: (state) => state.environment.environment.external_token,
      appointments: (state) => state.appointments.appointments,
      closeModal: (state) => state.root.closeModal,
    }),
  },
  mounted() {
    const $main = document.querySelector("html");
    if ($main) {
      $main.scrollTo(0, $main.scrollHeight);
    }
  },
  watch: {
    error(val) { // olvalue y el value new PARA EL ACCEPTED O ERROR
      if (val) {
        console.error(val);
      }
    },
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal"
    ]),
    ...mapActions("formDocuments", [
      "setFormDocuments",
      "handleContinueDocuments",
      "setLoading",
    ]),
    ...mapMutations("formDocuments", {
      setStateFormDocuments: "setState",
    }),
    handleAddForm() {
      this.setStateFormDocuments({ key: "formSelectedForEdit", value: null, });
      this.changeTrip("FormDocumentsAdd");
    },
    questionDelete(fd, fdindex) {
      this.handleShowModal(loadingMessage({
        title: "Seguro que desea eliminar esta fórmula",
        state: "danger",
        otherButtonCancelDelete: true,
        onClickDelete: () => this.deleteForm(fd, fdindex),
      }));
    },
    deleteForm(formDocument) {
      if (this.formDocuments.length === 1) {
        this.setFormDocuments([]);
        return;
      }
      const filteredDocuments = this.formDocuments.filter((formDocumentScope) => formDocumentScope.id !== formDocument.id);

      this.setFormDocuments(filteredDocuments || []);
    },
    async handleContinue() {
      this.setLoading(true);
      this.handleShowModal(loadingMessage({
        title: this.formDocuments.length === 1 ? "Validando fórmula..." : "Validando fórmulas...",
        state: "loading",
      }));
      
      const $form = document.getElementById("formUserValidationDocs");
      const newForm = new FormData($form);

      const information = [];
      for (const formDocument of this.formDocuments) {
        information.push({
          numero: newForm.get(formDocument.id),
          ...formDocument,
        });
      }
      
      // Check if formula number is not in appointments
      let exist = false;
      if(this.appointments.length > 0){
        for (let index = 0; index < this.appointments.length; index++) {
          const appointment = this.appointments[index];
          information.map(i => {
            if(appointment.reason.formulas.search(String(i.numero))  > -1 ){
              exist = true;
              this.setLoading(false);
              return this.handleShowModal(loadingMessage({
                title: `La fórmula ${i.numero} ya tiene una cita agendada`,
                state: "danger",
              }));
            }
          })
        }
      }

      // Send to check files on server
      setTimeout(async () => {
        if(exist) {
          this.closeModal();
          return;
        }

        
        await this.handleContinueDocuments({
          data: {
            formulas: information.map(i=>i.numero).join(','),
            tipo_documento: this.userData.dcType,
            numero_documento: this.userData.documentNumber,
            ciudad_id: this.userData.cityId,
            eps_id: this.eps.code
          },
          url: this.env.VUE_APP_EXTERNAL_SERVER + '/search-dispensing-points/client/1',
          token: this.external_token.access_token,
        }).catch((error) => {
          console.error(error);
          this.handleShowModal(loadingMessage({
            title: "Hubo un error al validar tus fórmulas",
            state: "danger",
          }));
        });
        
        if(this.formDocumentsFinalState){
          // Merge data
          information.map(info => {
            this.formDocumentsFinalState.forEach(document => {
              if(parseInt(info.numero) == parseInt(document.numero)){
                info.puntos=document.puntos;
                info.resultado=document.resultado;
                info.resultado_descripcion=document.resultado_descripcion;
                info.back_order=document.back_order;
                return info;
              }
            });
          });
        } else {
          return this.handleShowModal(loadingMessage({
            title: "Hubo un error al validar tus fórmulas",
            state: "danger",
          }));
        }

        this.closeModal();
        
        this.setFormDocuments(information || []);

        this.changeTrip("FormDocumentsValidation");
      }, 2000);

    },
    handleChangeForm(event) {
      const filteredForm = this.formDocuments.find((fd) => {
        console.log(fd, event.target.id); // FIXME: Arregla esto que se estropeo cuando se puso el API
        return fd.id === event.target.id;
      });
      filteredForm.numero = +event.target.value;
    },
    handleGoToEdition(formDocument) {
      this.setStateFormDocuments({ key: "formSelectedForEdit", value: formDocument, });
      this.changeTrip("FormDocumentsAdd");
    },
  },
}
</script>

<style scoped>
.ArrowScoped {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  background-color: #a5c8f382;
}

.maximagLoad {
  max-width: 30px;
}

.greenDk {
  border-bottom: 2px solid #3CA899;
  /* animation-delay: 2s;
  animation: greenToGray 1s alternate forwards; */
}

/* @keyframes greenToGray {
  0% {
    border-bottom: 2px solid #3CA899;
  }
  100% {
    border-bottom: 2px solid #c0c0c0;
  }
} */
</style>